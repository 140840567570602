<template>
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo" mode="horizontal"
      @select="handleSelect">
      <el-menu-item v-for="(item,index) in NavMenuList" :key="item.value" :index="index + ''">{{item.name}}</el-menu-item>
    </el-menu>
      <el-table :data="tableData" style="width: 100%"    row-key="id" :tree-props="{children: 'children'}">

      <el-table-column label="标题名称" prop="title">
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template slot-scope="scope">
          <el-button type="primary" v-if="scope.row.is_show == 0 && scope.row.pid == 0" @click="isHideAoShow(scope.row.id)">显示</el-button>
          <el-button type="success" v-if="scope.row.is_show == 1 && scope.row.pid == 0" @click="isHideAoShow(scope.row.id)">隐藏</el-button>
          <el-button type="primary" v-if="scope.row.modular_type == 'radio' || scope.row.modular_type == 'checkbox'"
            @click="edit(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑 -->
    <el-dialog
      title="添加或者删除选项"
      :visible.sync="dialogVisible"
      width="60%">
      <div class="radio">
        <h2 class="name_title">添加选项内容</h2>
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">点击添加选项</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 模板切换时的提示 -->
    <div class="big_box">
      <transition name="el-zoom-in-center">
        <div v-show="show" class="transition-box">配置{{templateName}}</div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: null, // 页面加载
      NavMenuList: [], // 6个模板
      tableData: [], // 表单数据
      activeIndex: "0", // 默认选中的导航
      show: false, // 切换模板时的提示
      templateName: "", // 对应大模板标题
      dialogVisible: false,
      activeName: "basic",
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      id: ""
    };
  },
  created() {
    this.getTab();
    // this.getTabeList();
  },
  methods: {
    // 整个页面加载loading
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    // 获取6个需要定制的模板
    async getTab() {
      this.openFullScreen2();
      const res = await this.axios.get("api/backend/templates");
      if (res.status_code === 200) {
        let list = res.data;
        this.NavMenuList = list;
        // this.getTableData();
        this.getTabeList();
        this.loading.close();
      } else if (res.status_code === 401) {
        this.loading.close();
        this.$router.push({ path: "/login" });
      }
    },
    async getTabeList() {
      this.openFullScreen2();
      const res = await this.axios.get(
        `/api/backend/new_template/${this.activeName}`
      );
      if (res.status_code === 200) {
        this.tableData = res.data;
        this.loading.close();
      }
      console.log(res);
    },
    // 切换导航模板
    handleSelect(key) {
      this.activeName = this.NavMenuList[key].value; // 获取模板名称字段
      this.templateName = this.NavMenuList[key].name;
      console.log(this.activeName);
      // this.getTableData();
      this.getTabeList();
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 1000);
    },
    async isHideAoShow(id) {
      const res = await this.axios.put(`/api/backend/new_template/${id}`);
      if (res.status_code === 200) {
        this.getTabeList();
      }
    },
    edit(row) {
      console.log(row.id);
      this.dialogVisible = true;
      let arr = [];
      row.options.forEach(item => {
        arr.push(item.name);
      });
      this.dynamicTags = arr;
      this.id = row.id;
    },
    async determine() {
      const res = await this.axios.post("/api/backend/new_template/info", {
        template_id: this.id,
        options: this.dynamicTags
      });
      if (res.status_code === 200) {
        this.dialogVisible = false;
        this.$message.success("添加成功");
      } else {
        this.$message.error(res.error);
      }
    },
    handleInputConfirm() {
      console.log(this.inputValue);
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    }
  }
};
</script>

<style lang="less" scoped>
.transition-box {
  margin-bottom: 10px;
  width: 4rem /* 400/100 */;
  height: 2rem /* 200/100 */;
  border-radius: 4px;
  background-color: #545c64;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
  font-size: 0.2rem; /* 20/100 */
}
.big_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.container {
  float: right;
  .box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* 让元素垂直排列 这里就是让包含图片的div和文字垂直排列 */
    flex-direction: column;
    width: 200px;
    height: 100px;
    margin: 20px;
    /* 鼠标放上去变成小手 */
    cursor: pointer;
  }
  .box .img {
    /* 这里让图片在盒子里垂直+水平居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    /* 圆角属性 */
    border-radius: 20px;
    /* 盒子阴影 */
    box-shadow: 18px 18px 30px rgba(0, 0, 0, 0.2),
      -18px -18px 30px rgb(255, 255, 255);
    /* 过渡时间 ease-out是指先快速 后慢速 */
    transition: all 0.2s ease-out;
  }
  .img {
    i {
      font-size: 0.3rem /* 20/100 */;
      transition: all 0.2s ease-out;
    }
  }
  // .box .img img {
  //   width: 60px;
  //   transition: all 0.2s ease-out;
  // }
  .container .box p {
    color: slategrey;
  }
  .box .img:hover {
    /* inset 是内部阴影 默认是外部阴影outset */
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 rgba(255, 255, 255, 0.8),
      inset 18px 18px 30px rgba(0, 0, 0, 0.1),
      inset -18px -18px 30px rgba(255, 255, 255, 1);
  }
  .box .img:hover img {
    width: 58px;
  }
}
.el-drawer__wrapper {
  i {
    margin-left: 0.2rem; /* 20/100 */
    color: red;
  }
  span {
    font-size: 0.16rem /* 20/100 */;
  }
  p {
    font-size: 0.16rem /* 16/100 */;
    margin-top: 0.2rem /* 20/100 */;
    margin-bottom: 0.2rem /* 20/100 */;
    margin-left: 0.2rem; /* 20/100 */
  }
  .el-input {
    width: 60%;
    margin-left: 0.2rem; /* 20/100 */
  }
  .el-row {
    margin-top: 0.5rem /* 50/100 */;
    margin-left: 0.2rem /* 20/100 */;
  }
}
</style>


