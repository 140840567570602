<template>
  <div class="custom_details">
    <div class="custom_header">
      <h2 v-if="status == 'pre_sale_draft' && pre_sale_supervisor_id">需求定制申请表（草稿状态）</h2>
      <h2 v-if="status == 'pre_sale_draft' && !pre_sale_supervisor_id">需求定制申请表（草稿状态--被驳回）</h2>
      <h2 v-if="status == 'pre_sale_supervisor_approval'">需求定制申请表（营销主管审核状态）</h2>
      <h2 v-if="status == 'product_supervisor_approval'">需求定制申请表（产品主管审核状态）</h2>
      <h2 v-if="status == 'product_manager_approval'">需求定制申请表（产品经理填写方案）</h2>
      <h2 v-if="status == 'pre_sale_draft_confirm'">需求定制申请表（售前确认方案状态）</h2>
      <h2 v-if="status == 'pre_sale_draft_confirm_submit'">需求定制申请表（售前提交方案到客户）</h2>
      <h2 v-if="status == 'pre_sale_confirm_programme'">需求定制申请表（客户方案认可）</h2>
      <h2 v-if="status == 'pre_sale_confirm_complete'">需求定制申请表（确认成单）</h2>
      <h2 v-if="status == 'pre_sale_shelve'">需求定制申请表（项目搁置）</h2>
      <p class="p1">项目编号：{{number}}  </p>
      <p class="p2">申请日期：{{created_at}}  </p>
    </div>
    <ul class="ul_info">
      <li>申请人信息</li>
      <li>
        <p v-if="status == 'pre_sale_draft' && pre_sale_supervisor_id"> 项目状态: 草稿状态</p>
        <p v-if="status == 'pre_sale_draft' && !pre_sale_supervisor_id"> 项目状态: （草稿状态--被驳回）</p>
        <p v-if="status == 'pre_sale_supervisor_approval'">项目状态: 营销主管审核状态</p>
        <p v-if="status == 'product_supervisor_approval'">项目状态: 产品主管审核状态</p>
        <p v-if="status == 'product_manager_approval'">项目状态: 产品经理填写方案</p>
        <p v-if="status == 'pre_sale_draft_confirm'">项目状态: 售前确认方案状态</p>
        <p v-if="status == 'pre_sale_draft_confirm_submit'">项目状态: 售前提交方案到客户</p>
        <p v-if="status == 'pre_sale_confirm_programme'">项目状态: 客户方案认可</p>
        <p v-if="status == 'pre_sale_confirm_complete'"> 项目状态: 确认成单</p>
        <p v-if="status == 'pre_sale_shelve'">项目状态: 项目搁置</p>
        <p>项目人员: {{member.name}}</p>
        <p>项目时间: {{member.created_at}}</p>
      </li>
    </ul>
    <ul v-if="basicList" class="ul_info">
      <li>项目基本信息</li>
      <li>
        <p>项目名称: {{basicList.entry_name}}</p>
        <p>客户经理: {{basicList.customers}}</p>
        <p>所属行业: {{basicList.industry }}</p>
        <p>客户名称: {{basicList.customer_name }}</p>
        <!-- <p>渠道名称: {{basicList.channel_name }}</p> -->
        <p>项目背景: {{basicList.scene }}</p>
        <p>是否需要和第三方合作: {{basicList.cooperation }}</p>
        <p>预估销售数量: {{basicList.num}}</p>
        <p>价格区间: {{basicList.start_total + '-' +  basicList.end_total}}</p>
        <p>样机交付时间: {{basicList.prototype_delivery_time }}</p>
        <p>批量交付时间: {{basicList.batch_delivery_time }}</p>
      </li>
    </ul>
    <ul v-if="hardwareList" class="ul_info">
      <li>硬件配置需求</li>
      <li>
        <p>需求的产品品类: {{hardwareList.demand_category_text}}</p>
        <p>认证需求: {{hardwareList.confirm_demand }}</p>
        <p>工作温湿度: {{hardwareList.specs.work_humiture }}</p>
        <p>储存温湿度: {{hardwareList.specs.storage_humiture }}</p>
        <p>防水防尘: {{hardwareList.prevent }}</p>
        <p>OP SHOCK指标: {{hardwareList.OPS_HOCK }}</p>
        <p>OP-VIBRATION指标: {{hardwareList.OP_VIBRATION }}</p>
        <p>输入电压: {{hardwareList.voltage }}</p>
        <p>环境需求其它: {{hardwareList.ambient_other}}</p>
        <p>尺寸需求: {{hardwareList.size }}</p>
        <p>CPU: {{hardwareList.cpu }}</p>
        <p>内存: {{hardwareList.memory }}</p>
        <p>存储: {{hardwareList.storage }}</p>
        <p>网口: {{hardwareList.network }}</p>
        <p>串口: {{hardwareList.sata }}</p>
        <p>并口: {{hardwareList.parallel }}</p>
        <p>USB: {{hardwareList.usb}}</p>
        <p>PCI: {{hardwareList.pci }}</p>
        <p>PCIe: {{hardwareList.pcie }}</p>
        <p>光驱: {{hardwareList.cd_drive }}</p>
        <p>视频输出接口: {{hardwareList.video }}</p>
        <p>WIFI: {{hardwareList.wifi }}</p>
        <p>Panel: {{hardwareList.panel }}</p>
        <p>其它: {{hardwareList.dispose_other }}</p>
      </li>
    </ul>
    <ul v-if="X86list" class="ul_info">
      <li>软件定制需求X86</li>
      <li>
        <p>操作系统版本: {{X86list.edition}}</p>
        <p>软件: {{X86list.software}}</p>
      </li>
    </ul>
    <ul v-if="ARMlist" class="ul_info">
      <li>软件定制需求ARM</li>
      <li>
        <p>需要适配的OS版本: {{ARMlist.edition}}</p>
        <p>串口设备: {{ARMlist.sata}}</p>
        <p>摄像头设备: {{ARMlist.camera}}</p>
        <p>显示设备: {{ARMlist.show}}</p>
        <p>需要特殊适配的硬件设备其他: {{ARMlist.special_other}}</p>
        <p>需要集成的软件及服务: {{ARMlist.software_services }}</p>
        <p>UI定制需求: {{ARMlist.ui}}</p>
        <p>固件升级方式: {{ARMlist.firmware_text}}</p>
        <p>软件认证要求: {{ARMlist.attestation_text}}</p>
        <p>需要软件屏蔽的功能: {{ARMlist.shield}}</p>
        <p>其它: {{ARMlist.arm_other}}</p>
      </li>
    </ul>
    <ul v-if="partsList" class="ul_info">
      <li>随机配件以及资料</li>
      <li>
        <p>资料: {{partsList.material_text}}</p>
        <p>线缆: {{partsList.cable}}</p>
        <p>挂架: {{partsList.pylons}}</p>
        <p>键鼠: {{partsList.mouse}}</p>
        <p>其它需求: {{partsList.other_needs }}</p>
      </li>
    </ul>
    <ul v-if="brandList" class="ul_info">
      <li>品牌及服务</li>
      <li>
        <p>品牌需求: {{brandList.brand_demand}}</p>
        <p>年限: {{brandList.year}}</p>
        <p>服务模式: {{brandList.pattern}}</p>
      </li>
    </ul>
    <div class="block">
      <el-tag effect="dark">操作记录</el-tag>
      <el-timeline>
        <el-timeline-item :timestamp="item.created_at" placement="top"
          v-for="item in RecordList"
          :key="item.id"
          :icon="item.event.icon"
          :type="item.event.type"
          :color='item.event.color'>
          <el-card>
            <p>{{item.content}} </p>
            <div class="status-information">
              <p class="ji-bie" v-if="item.feedback.level">优先级 : {{item.feedback.level}}</p>
              <p class="fang-an"  v-if="item.feedback.programme">方案名称 : {{item.feedback.programme}}</p>
              <p class="shuo-ming"  v-if="item.feedback.explain">说明 : {{item.feedback.explain}}</p>
              <p class="mi-ma"  v-if="item.feedback.complete_num">成单数量 : {{item.feedback.complete_num}}</p>
              <p class="mi-ma"  v-if="item.feedback.complete_tine">成单日期 : {{item.feedback.complete_tine}}</p>
              <template v-for="(imgsrc,index) in item.feedback.imgurl">
                <img class="r_img" :src="'http://iotapi.joinusad.com' + imgsrc" :key="index" >
              </template>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      member: "", // 申请人信息
      status: "",
      pre_sale_supervisor_id: "",
      list: [],
      basicList: null,
      hardwareList: null,
      X86list: null,
      ARMlist: null,
      partsList: null,
      brandList: null,
      RecordList: [],
      created_at: "",
      number: ""
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    console.log(this.id);
    this.getlist();
  },
  methods: {
    // 项目信息
    async getlist() {
      const res = await this.axios.get(`/api/backend/project/${this.id}`);
      console.log(res);
      if (res.status_code === 200) {
        this.status = res.data.status;
        this.pre_sale_supervisor_id = res.data.pre_sale_supervisor_id;
        this.basicList = res.data.items[0].itemable;
        this.hardwareList = res.data.items[1].itemable;
        this.X86list = res.data.items[2].itemable;
        this.ARMlist = res.data.items[3].itemable;
        this.partsList = res.data.items[4].itemable;
        this.brandList = res.data.items[5].itemable;
        this.member = res.data.member;
        this.RecordList = res.data.logs;
        this.created_at = res.data.created_at;
        this.number = res.data.number;
        console.log(this.hardwareList);
      }
    },
    // 操作记录
    // async getRecord(){
    //   const res = await this.axios
    // },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>


<style lang="less" scoped>
.custom_details {
  .custom_header {
    width: 100%;
    height: 1.5rem /* 200/100 */;
    background-color: #fff;
    padding-top: 0.5rem /* 50/100 */;
    padding-left: 0.15rem /* 15/100 */;
    padding-right: 0.15rem /* 15/100 */;
    h2 {
      text-align: center;
      font-size: 0.2rem /* 30/100 */;
      font-weight: 700;
    }
    .p1 {
      float: left;
      margin-top: 0.25rem /* 25/100 */;
    }
    .p2 {
      float: right;
      margin-top: 0.25rem /* 25/100 */;
    }
  }
  .ul_info {
    li {
      // height: 0.46rem /* 46/100 */;
      background-color: #fff;
      line-height: 0.46rem; /* 46/100 */
      overflow: hidden;
      padding-left: 0.4rem /* 20/100 */;
      p {
        float: left;
        width: 33.3%;
      }
    }
    li:first-child {
      text-align: center;
      background-color: #edf2f6;
    }
  }
  .block {
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    .el-tag {
      margin-bottom: 0.2rem;
    }
    .r_img {
      width: 2rem;
      float: left;
      margin-bottom: 10px;
    }
  }
}
</style>

